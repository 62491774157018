import React, { useRef } from 'react';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent } from "react";
import 'src/App.css';
import { Button, Form } from 'react-bootstrap';
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import NzConfig from 'src/Common/NzConfig';
import { Spinner } from "react-bootstrap";

function isValidNz(nz: string) {
    const id = parseInt(nz);
    return (
        typeof id === "number" && isFinite(id) && Math.round(id) === id && id > 0
    );
}

const onSubmit = (event: any) => {
    event.preventDefault();
    // console.log("Form submission prevented");
};


function EnterNameZipPage() {
    const navigateHook = useNavigate();
    const [nameZip, setNameZip] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const focusRef = useRef<HTMLInputElement>(null);


    React.useEffect(() => {
        if (focusRef.current) {
            focusRef.current.focus(); // Autofocus
        }
        return () => {
            // TODO cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
        }
    }, [nameZip]);

    const handleGetName = async (
        nz: string
    ) => {
        const url = NzConfig.baseUrl + "spell/" + nz;
        console.log("Calling POST API", url, NzConfig.header);
        setShowSpinner(true);
        await axios
            .get(url, NzConfig.header)
            .then((response) => {
                const nameZip = response.data.wand;
                console.log("Success", response, response.data, nameZip,);

                setShowSpinner(false);

                //Navigate
                const nzState = {
                    fullName: response.data.fullName,
                    nz: response.data.wand
                };

                navigateHook("/showname", { state: nzState });
            })
            .catch((error) => {
                console.error("There was an error!", error);

                setErrorMessage("The NameZip you provided doesn't exist. Please try again");
                if (focusRef.current) {
                    focusRef.current.focus(); // Autofocus
                }
                //alert("Something went wrong " + error);
                // TODO UX
            });

        setShowSpinner(false);
    };

    return (
        <div>
            <Container fluid="md">
                <Row>
                    <Form className='nz-center' onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>NameZip</Form.Label>
                            <Form.Control type="number" placeholder="NameZip here.."
                                ref={focusRef}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    var windo: any = window;
                                    if (windo.trace === true) {
                                        console.log("TypeScript e.target", e.target);
                                        console.log("e.target.value", e.target.value);
                                    }
                                    setErrorMessage("");
                                    setNameZip(e.target.value);
                                    if (!isValidNz(e.target.value) && e.target.value !== "") { // Don't use nameZip variable here
                                        setErrorMessage("Invalid NameZip");
                                    }

                                    console.log("isValidNz", nameZip, isValidNz(nameZip));
                                }} />
                            <Form.Text className="text-muted">
                                A NameZip is a short code like 25123
                            </Form.Text>
                            <div className="nz-error-inline">
                                {errorMessage}
                            </div>
                        </Form.Group>

                        <Button variant="primary" type="submit"
                            disabled={!isValidNz(nameZip) || showSpinner}
                            onClick={() => {
                                console.log("Get Name clicked");
                                handleGetName(nameZip);
                            }}>
                            Get Name
                        </Button>

                        <div className="nz-spinner">
                            <Spinner animation="border" size="sm" hidden={!showSpinner} />
                        </div>
                    </Form>
                </Row>
            </Container>

        </div>
    );
}

export default EnterNameZipPage;
