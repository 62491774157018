import React, { useRef, useState } from 'react';
import '../App.css';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { Card, Badge, Container } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import QRcode from "react-qr-code";

function ShowNameZipPage() {

    let location = useLocation();
    const toolTipTarget = useRef(null);
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        //TODO

        console.log("location", location);
        console.log("location.pathname", location.pathname); // 
        console.log("location.search", location.search); // 
        console.log("location.state", location.state); // 
    }, [location]);

    const myState: any = location.state;
    const fullName = myState.fullName;
    const nz = myState.nz;
    const shareLink = "NameZippy.com/" + nz;
    return (
        <Container fluid="md">
            <div className='nz-center-shownz'>
                <Card className="border-success text-dark">
                    <Card.Header className='bg-success text-white'>Your NameZip</Card.Header>
                    <Card.Body>
                        <Card.Title> {nz} </Card.Title>
                        <Card.Text>
                            When someone asks "can you spell your name" over the phone, say

                            <div className='nz-nz'>
                                <Badge pill className='bg-success'>
                                    namezippy.com/{nz}
                                </Badge>
                            </div>

                            <Button
                                ref={toolTipTarget}
                                variant="outline-info"
                                size="sm"
                                style={{ "margin": "10px 0 0 0" }}
                                onClick={() => {
                                    navigator.clipboard.writeText(shareLink);
                                    setShow(!show);
                                    setInterval(() => {
                                        setShow(false);
                                    }, 3000);
                                }}
                            >
                                📋 Copy
                            </Button>
                            <Overlay
                                target={toolTipTarget.current}
                                show={show}
                                placement="bottom"
                            >
                                {(props) => (
                                    <Tooltip id="t1" {...props}>
                                        Copied..
                                    </Tooltip>
                                )}
                            </Overlay>

                            <br />
                            {/* <div>
                                <span className='text-muted nz-info'>Note it down somewhere. No worries if you forget it, you can always get it from NameZippy</span>
                            </div> */}
                            <br />
                            <div style={{ marginTop: "2em" }}>
                                <QRcode value={shareLink} />
                            </div>

                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
}

export default ShowNameZipPage;
