import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent } from "react";
import '../App.css';
import { Button, Form } from 'react-bootstrap';
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import NzConfig from 'src/Common/NzConfig';
import { Spinner } from "react-bootstrap";

const onSubmit = (event: any) => {
    event.preventDefault();
    // console.log("Form submission prevented");
};

function isValidFullName(fullName: string) {
    return (
        fullName !== undefined && fullName !== "" && fullName.length > 1
    );
}

function Home() {
    const navigateHook = useNavigate();
    const [fullName, setFullName] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);

    // must be typed, to avoid error : Property 'focus' does not exist on type 'never'.
    const focusRef = useRef<HTMLInputElement>(null);
    //const [blaRef, setBlaRef] = useState<React.RefObject<HTMLInputElement>>(); 

    useEffect(() => {
        //console.log("focusRef", focusRef);
        if (focusRef.current) {
            //console.log("calling focusRef.current.focus()");
            focusRef.current.focus();
        }
        return () => {
            // TODO cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
        }
    }, [fullName, focusRef]);

    const makeNameZip = async (
        fullName: string
    ) => {

        // TODO check whether user entered a number by mistake. Try fetching Name in that case

        var silverRequest = {
            fullName: fullName,
        };
        const url = NzConfig.baseUrl + "spell/silver";
        console.log("Calling POST API", url, silverRequest, NzConfig.header);
        setShowSpinner(true);
        await axios
            .post(url, silverRequest, NzConfig.header)
            .then((response) => {
                const nameZip = response.data.wand;
                console.log("Success", response, response.data, nameZip,);

                setShowSpinner(false);

                //Navigate
                const nzState = {
                    fullName: response.data.fullName,
                    nz: response.data.wand
                };

                navigateHook("/shownz", { state: nzState });
            })
            .catch((error) => {
                console.error("There was an error!", error);
                //alert("Something went wrong " + error);
            });

        setShowSpinner(false);
    };

    const handleGetName = async (
    ) => {
        navigateHook("/enternz");
    };

    return (
        <>
            <Container fluid="md">
                <Row>
                    <Form className='nz-center' onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="text" placeholder="Full name here.."
                                ref={focusRef}
                                //ref={(c: any) => { setBlaRef(c); }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    var windo: any = window;
                                    if (windo.trace === true) {
                                        console.log("TypeScript e.target", e.target);
                                        console.log("e.target.value", e.target.value);
                                    }
                                    setFullName(e.target.value);
                                }} />

                        </Form.Group>

                        <Button variant="primary" type="submit"
                            disabled={!isValidFullName(fullName) || showSpinner}
                            onClick={() => {
                                console.log("Get NameZip clicked");
                                makeNameZip(fullName);
                            }}
                        >
                            Get NameZip
                        </Button>
                        <div></div>
                        <Form.Text className="text-muted">
                            A NameZip is a short code like 25123
                        </Form.Text>
                        <div className="nz-spinner">
                            <Spinner animation="border" size="sm" hidden={!showSpinner} />
                        </div>

                    </Form>
                </Row>

                <Row>
                    <div className='nz-line'>
                        <hr
                            style={{
                                background: 'grey',
                                color: '#AAAAAA',
                                borderColor: '',
                                height: '1px',
                            }}
                        />
                        <p className='text-muted'>Someone gave you a NameZip? </p>
                        <Button variant="primary"
                            onClick={() => {
                                console.log("Get Name clicked");
                                handleGetName();
                            }}>
                            Get Name
                        </Button>
                    </div>

                </Row>

            </Container>

        </>
    );
}

export default Home;
