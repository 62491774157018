import React, { useState } from 'react';
import '../App.css';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Card, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NzConfig from 'src/Common/NzConfig';
import axios from 'axios';
import isValidNz from 'src/Common/Util';

const sleepNZ = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);

function DynamicPage() {

    let navigateHook = useNavigate();
    let location = useLocation();
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [attempts, setAttempts] = useState(0);

    const handleRetryPressed = () => {
        navigateHook("/enternz");
    }

    let { id } = useParams(); // Grab the dynamic part from Url. Example www.namezippy.com/123
    const nz: string = id ?? "";

    React.useEffect(() => {
        //TODO
        console.log("location", location);
        console.log("process.env", process.env); //TODO check value in Prod

        setAttempts(attempts + 1);
        if (attempts < 1) {     // TODO implement retry mechanism later. Note: attempts = 0 on this line, despite the +1
            handleGetName(nz);
        }

        // Cleanup function TODO
        return () => { }

    }, [location, showSpinner]); // Adding attempts to this array will lead to an infinite reload loop

    const handleGetName = async (
        nz: string
    ) => {

        console.log("-----------------------handleGetName attempt", attempts);
        if (!isValidNz(nz)) {
            console.log("Invalid NameZip", nz);

            //setShowModal(true);
            setErrorMessage("The NameZip you provided is invalid. Please try again");
        } else {

            const url = NzConfig.baseUrl + "spell/" + nz;
            console.log("Calling POST API", url, NzConfig.header);
            setShowSpinner(true);

            if (process?.env?.NODE_ENV === 'development') {
                await sleepNZ(5000); // TODO only for local dev
            }

            let nzState = {};
            await axios
                .get(url, NzConfig.header)
                .then((response) => {
                    const nameZip = response.data.wand;
                    console.log("Success", response, response.data, nameZip,);

                    setShowSpinner(false);


                    nzState = {
                        fullName: response.data.fullName,
                        nz: response.data.wand
                    };

                    //Navigate
                    navigateHook("/showname", { state: nzState });
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                    setErrorMessage("Oops, something went wrong. Please try again");

                    // TODO UX
                });

            setShowSpinner(false);
        }
    };

    return (
        <div>
            <Container fluid="md">
                <Row>
                    <div className='nz-center'>
                        <Card className="border-success text-dark">
                            <Card.Header className='bg-success text-white'>NameZip {nz}</Card.Header>
                            <Card.Body>
                                <div hidden={errorMessage !== ""}>
                                    Won't be long...
                                    <div className="nz-spinner">
                                        <Spinner animation="border" size="sm" hidden={!showSpinner} />
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <span className='text-muted nz-info'>
                                        NameZippy doesn't collect your personal information
                                    </span>
                                </div>
                                <div hidden={errorMessage === ""}>
                                    {errorMessage}
                                    <br />
                                    <Button variant="primary"
                                        onClick={() => {
                                            console.log("Retry clicked");
                                            handleRetryPressed();
                                        }}>
                                        Try Again
                                    </Button>
                                    <br />
                                    <br />
                                    <span className='text-muted nz-info'>
                                        NameZippy doesn't collect your personal information
                                    </span>
                                </div>

                            </Card.Body>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    )

}
export default DynamicPage;

