import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as NameZippyRouter } from "react-router-dom";
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  <React.StrictMode>
    <NameZippyRouter>
      <App />
    </NameZippyRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
