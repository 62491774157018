import 'src/App.css';
import { Container, Row, Col } from "react-bootstrap";

const Header = () => {

    return (
        <Container fluid="md">
            <Row>
                <Col>
                    <div>
                        <a href="/"><h3 className='nz-logo'>NameZippy</h3></a>
                    </div>
                </Col>
            </Row>
        </Container>

    );
};

export default Header;
