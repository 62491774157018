import React, { useRef, useState } from 'react';
import '../App.css';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { Card, Container } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

function ShowNamePage() {

    let location = useLocation();
    const toolTipTarget = useRef(null);
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        //TODO

        console.log("location", location);
    }, [location]);

    const myState: any = location.state;
    const fullName = myState.fullName;
    const nz = myState.nz;


    return (
        <Container fluid="md">
            <div className='nz-center'>
                <Card className="border-success text-dark">
                    <Card.Header className='bg-success text-white'>NameZip {nz}</Card.Header>
                    <Card.Body>
                        <Card.Title> {fullName} </Card.Title>
                        <Card.Text>
                            <Button
                                ref={toolTipTarget}
                                variant="outline-info"
                                size="sm"
                                style={{ "margin": "10px 0 0 0" }}
                                onClick={() => {
                                    navigator.clipboard.writeText(fullName);
                                    setShow(!show);
                                    setInterval(() => {
                                        setShow(false);
                                    }, 3000);
                                }}
                            >
                                📋 Copy
                            </Button>
                            <Overlay
                                target={toolTipTarget.current}
                                show={show}
                                placement="bottom"
                            >
                                {(props) => (
                                    <Tooltip id="t1" {...props}>
                                        Copied..
                                    </Tooltip>
                                )}
                            </Overlay>


                            <br />
                            <br />
                            <br />
                            <span className='text-muted nz-info'
                            >NameZippy doesn't collect your personal information</span>

                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
}

export default ShowNamePage;
