import './App.css';
import { Route, Routes } from "react-router-dom";
import ShowNamePage from './Components/ShowNamePage';
import EnterNameZipPage from './Components/EnterNameZipPage';
import ShowNameZipPage from './Components/ShowNameZipPage';
import Home from './Components/Home';
import Header from './Components/Common/Header';
import DynamicPage from './Components/DynamicPage';

function App() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
      {/*
         Routing changed a lot from v5 to v6 react-router-dom
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
        </Switch> */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="shownz" element={<ShowNameZipPage />} />
        <Route path="showname" element={<ShowNamePage />} />
        <Route path="enternz" element={<EnterNameZipPage />} />

        <Route path="/:id" element={<DynamicPage />} />

        {/* Ensure all other paths lead to home page */}
        <Route path='*' element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
